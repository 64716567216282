import React, { useRef, useState, useEffect, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import LinkIcon from '@atlaskit/icon/glyph/link';
import Button from '@atlaskit/button/standard-button';
import type { Appearance } from '@atlaskit/button/types';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { PositionType } from '@atlaskit/tooltip';

import { Attribution, ErrorDisplay, withErrorBoundary } from '@confluence/error-boundary';
import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import { END } from '@confluence/navdex';
import {
	COPY_CONTENT_LINK_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceSuccess,
	ExperienceStop,
} from '@confluence/experience-tracker';

import { useCopyContentLink } from './useCopyContentLink';

const i18n = defineMessages({
	copyLinkLabel: {
		id: 'share.copylink.icon.label',
		defaultMessage: 'Copy link',
		description: 'Label and tooltip for the button to copy content URL',
	},
	copiedTextLabel: {
		id: 'share.copylink.copied.tooltip',
		defaultMessage: 'Copied!',
		description:
			'Text for the copy link button tooltip when content URL has been successfully copied',
	},
	errorCopyingTextLabel: {
		id: 'share.copylink.error.tooltip',
		defaultMessage: 'Something went wrong.',
		description:
			'Text for the copy link button tooltip when an error occurred while trying to copy link',
	},
});

const RESET_COPYTEXT_TIMEOUT = 5000;
export const ACTION_SUBJECT_ID = 'copyShareLink';
export enum PAGE_MODE {
	VIEW = 'view',
	EDIT = 'edit',
}

export type CopyLinkProps = {
	pageMode?: PAGE_MODE;
	contentType: string;
	contentId: string;
	spaceKey: string;
	source: string;
	shouldResetOnHover?: boolean;
	buttonAppearance?: Appearance;
	tooltipPosition?: PositionType;
};

const CopyLinkComponent: React.FC<CopyLinkProps> = ({
	pageMode,
	contentType,
	contentId,
	spaceKey,
	source,
	shouldResetOnHover = false,
	buttonAppearance = 'subtle',
	tooltipPosition = 'bottom',
}) => {
	const intl = useIntl();
	const markItemRef = useRef<((item: HEADER_ITEM_KEYS) => void) | undefined>();
	const [isCopied, setIsCopied] = useState<boolean>(false);
	const [errorDuringCopy, setErrorDuringCopy] = useState<Error | undefined>();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cleanupCopiedTimer = useRef<NodeJS.Timeout>();
	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		if (isCopied) {
			cleanupCopiedTimer.current = setTimeout(() => setIsCopied(false), RESET_COPYTEXT_TIMEOUT);
		}
		return () => {
			clearTimeout(cleanupCopiedTimer.current);
		};
	}, [isCopied]);

	useEffect(() => {
		markItemRef.current?.(HEADER_ITEM_KEYS.COPY_LINK);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				actionSubject: 'copyShareLink',
				action: 'shown',
				source,
				attributes: {
					contentType,
					pageMode,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentType, source, pageMode]);

	const { queryError, unsupportedLinkError, copyContentLink } = useCopyContentLink({
		pageMode,
		contentType,
		contentId,
		spaceKey,
		source,
	});

	const handleCopyLink = async () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: ACTION_SUBJECT_ID,
				source,
				attributes: {
					pageMode,
					contentType,
					navdexPointType: END,
				},
			},
		}).fire();

		try {
			await copyContentLink();
		} catch (e) {
			setErrorDuringCopy(e);
		}
		setIsCopied(true);
	};

	const handleHover = () => {
		experienceTracker.start({
			name: COPY_CONTENT_LINK_EXPERIENCE,
		});
	};

	// report errors from the hook's query silently since we have fallback link retrieval
	// methods, but show user error tooltip text and fail experience if we actually
	// couldn't get any link to copy or the copy itself failed
	const copyError = unsupportedLinkError || errorDuringCopy;
	const copyLinkText = intl.formatMessage(i18n.copyLinkLabel);
	const confirmationText = intl.formatMessage(
		copyError ? i18n.errorCopyingTextLabel : i18n.copiedTextLabel,
	);
	const isAnyError = Boolean(queryError || copyError);

	return (
		<>
			{isAnyError && <ErrorDisplay error={queryError || (copyError as Error)} />}
			{Boolean(copyError) && (
				<ExperienceStop name={COPY_CONTENT_LINK_EXPERIENCE} error={copyError} />
			)}
			<Subscribe to={[HeaderItemsContainer]}>
				{({ addItem, markItem }: HeaderItemsContainer) => {
					addItem(HEADER_ITEM_KEYS.COPY_LINK);
					markItemRef.current = markItem;
					return (
						<Tooltip
							content={isCopied ? confirmationText : copyLinkText}
							position={tooltipPosition}
							// force remount after copying so tooltip is regenerated
							// in the correct position (otherwise will be off center)
							key={isCopied ? 'copied' : 'default'}
						>
							<Button
								testId="copy-link-button"
								aria-label={copyLinkText}
								appearance={buttonAppearance}
								iconBefore={
									<LinkIcon
										label=""
										primaryColor={isCopied ? token('color.icon.selected', B400) : undefined}
										size="medium"
									/>
								}
								onClick={handleCopyLink}
								onMouseEnter={handleHover}
								// Some consumers of CopyLink only reveal the button on hover, and
								// for those ones we need to reset the button to default post hover.
								// state after mouse leaves the component (because the button gets
								// hidden when no longer hovering)
								onMouseLeave={() => {
									if (shouldResetOnHover) setIsCopied(false);
									experienceTracker.abort({
										name: COPY_CONTENT_LINK_EXPERIENCE,
										reason: 'user navigated away',
									});
								}}
								shouldFitContainer
							/>
						</Tooltip>
					);
				}}
			</Subscribe>
			{isCopied && <ExperienceSuccess name={COPY_CONTENT_LINK_EXPERIENCE} />}
		</>
	);
};

export const CopyLink = withErrorBoundary({
	attribution: Attribution.PERMISSIONS_EXPERIENCE,
})(CopyLinkComponent);
