import React, { useState, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { CustomItem } from '@atlaskit/menu';
import Button from '@atlaskit/button/new';

import { ProfileAvatar } from '@confluence/profile';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

const nameStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	width: '120px',
});

const containerStyles = xcss({
	height: '52px',
});

const containerChildStyles = xcss({
	height: '100%',
});

const i18n = defineMessages({
	inviteToEdit: {
		id: 'experiment-teammate-presence.menu.invite-to-edit',
		defaultMessage: 'Invite to edit',
		description:
			'The text is shown in button. When clicked, the user will be able to invite the user to edit the page',
	},
	inviteSent: {
		id: 'experiment-teammate-presence.menu.invite-sent',
		defaultMessage: 'Invite sent',
		description: 'The text is shown after invite to edit button clicked.',
	},
	sharePage: {
		id: 'experiment-teammate-presence.menu.share-page',
		defaultMessage: 'Share page',
		description:
			'The text is shown in button. When clicked, the user will be able to share the page with other users',
	},
	pageShared: {
		id: 'experiment-teammate-presence.menu.page-shared',
		defaultMessage: 'Page shared',
		description: 'The text is shown after Share page button clicked.',
	},
});

type TeammatePresenceSuggestionsSectionRowProps = {
	name: string;
	userId: string;
	handleShare: () => void;
	url: string;
};

export const TeammatePresenceSuggestionsSectionRow: React.FC<
	TeammatePresenceSuggestionsSectionRowProps
> = ({ name, handleShare, url, userId }) => {
	const [isClicked, setIsClicked] = useState<boolean>(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const isEditPage = useIsEditorPage();
	const shouldFireHoverEvent = useRef<boolean>(true);

	const handleHover = () => {
		if (shouldFireHoverEvent.current) {
			shouldFireHoverEvent.current = false;
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'hovered',
					actionSubject: 'link',
					source: 'presenceSuggestionsMenu',
					actionSubjectId: 'presenceAvatarProfile',
					attributes: {
						isEditPage,
					},
				},
			}).fire();
		}
	};

	const handleInviteToEditButtonClick = () => {
		handleShare();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'presenceInviteToEdit',
				source: 'presenceSuggestionsMenu',
				attributes: {
					isEditPage,
				},
			},
		}).fire();
		setIsClicked(true);
	};

	const [isNotClickedMessage, isClickedMessage] = isEditPage
		? [formatMessage(i18n.inviteToEdit), formatMessage(i18n.inviteSent)]
		: [formatMessage(i18n.sharePage), formatMessage(i18n.pageShared)];

	return (
		<CustomItem
			component={() => (
				<Box paddingInline="space.200" paddingBlock="space.100" xcss={containerStyles}>
					<Flex
						xcss={containerChildStyles}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Flex direction="row" alignItems="center" gap="space.150">
							<div onMouseEnter={handleHover}>
								<ProfileAvatar
									userId={userId}
									size="medium"
									name={name}
									src={url}
									trigger="hover"
									position="left-start"
									canViewProfile
									offset={[0, 24]}
								/>
							</div>
							<Box xcss={nameStyles}>{name}</Box>
						</Flex>
						<Button onClick={handleInviteToEditButtonClick} isDisabled={isClicked}>
							{isClicked ? isClickedMessage : isNotClickedMessage}
						</Button>
					</Flex>
				</Box>
			)}
		/>
	);
};
