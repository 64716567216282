import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { EditorPresenceAvatarGroupPlaceholder } from './EditorPresenceAvatarGroupPlaceholder';
import { EditorPresenceAvatarGroupLivePagePlaceholder } from './EditorPresenceAvatarGroupLivePagePlaceholder';

export const EditorPresenceAvatarGroupLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorPresenceAvatarGroup" */ './EditorPresenceAvatarGroup'
			)
		).EditorPresenceAvatarGroup,
	loading: ({ isEditorActionBarSSREnabled, isLivePage }) => {
		if (isEditorActionBarSSREnabled) {
			if (isLivePage) {
				return <EditorPresenceAvatarGroupLivePagePlaceholder />;
			} else {
				return <EditorPresenceAvatarGroupPlaceholder />;
			}
		} else {
			return null;
		}
	},
});
