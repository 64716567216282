import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import uuid from 'uuid/v4';

import { createSocketIOCollabProvider } from '@atlaskit/collab-provider/socket-io-provider';
import type { Provider } from '@atlaskit/collab-provider';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import {
	extractIsEditorVersionFabric,
	fetchEditorTypeAndVersionPropertyQuery,
} from '@confluence/load-edit-page';
import { getCollabProviderUrl, getUser } from '@confluence/fabric-providers';
import { fetchCollabPermissionToken, useNativeCollabState } from '@confluence/native-collab';
import { useSessionData } from '@confluence/session-data';
import { VIEW_BLOG, VIEW_BLOG_DATE_LEGACY } from '@confluence/named-routes';
import {
	ExperienceTrackerContext,
	TEAMMATE_PRESENCE_NCS_PROVIDER,
} from '@confluence/experience-tracker';
import { useRouteDataRef } from '@confluence/route-manager/entry-points/RouteState';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

/**
 * `useNCSProvider` is a custom hook designed specifically for the experiment
 * teammate presence. This hook is not intended for general use and
 * should not be used outside of the experiment. Please do not use or modify
 * this hook for other features without consulting the Land and Onboarding team.
 * `useNCSProvider` is a custom hook designed for managing and initializing
 * a collaboration provider for a given piece of content identified by `contentId`.
 * The hook establishes a connection with the NCS when the provider is set up.
 */
export const useNCSProvider = (contentId: string, skip: boolean = false) => {
	const [isLoading, setIsloading] = useState(true);
	const providerRef = useRef<Provider | null>(null);
	const [errors, setErrors] = useState<Error[] | null>(null);
	const [{ collabEditProvider }, { setCollabEditProvider }] = useNativeCollabState();
	const { cloudId, userId: clientId } = useSessionData();
	const isEditPage = useIsEditorPage();
	const isLivePage = useIsLivePage();
	const routeName = useRouteDataRef().current?.routeName;
	const isBlog = routeName === VIEW_BLOG.name || routeName === VIEW_BLOG_DATE_LEGACY.name;
	const handleError = (error: Error) => {
		experienceTracker.fail({
			name: TEAMMATE_PRESENCE_NCS_PROVIDER,
			error,
		});
		setErrors((prev) => (prev ? [...prev, error] : [error]));
	};
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const hasExperienceTrackerStartedRef = useRef<Boolean>(false);

	if (!hasExperienceTrackerStartedRef.current) {
		experienceTracker.start({
			name: TEAMMATE_PRESENCE_NCS_PROVIDER,
		});
		hasExperienceTrackerStartedRef.current = true;
	}

	const initializeNCSCollabProvider = useCallback(
		async () => {
			if (skip || !contentId || !cloudId || isEditPage) {
				setIsloading(false);
				experienceTracker.abort({
					name: TEAMMATE_PRESENCE_NCS_PROVIDER,
					reason: 'Aborted because a pre-requisite was not met',
				});
				return;
			}

			const permissionTokenRefresh = fetchCollabPermissionToken(contentId, undefined);
			const analyticsSource = 'teammatePresence:useNCSProvider';

			try {
				const isFabric = await fetchEditorTypeAndVersionPropertyQuery({
					contentId,
				})
					.then((result) => extractIsEditorVersionFabric(result))
					.catch(handleError);

				if (isFabric) {
					const documentAri = `ari:cloud:confluence:${cloudId}:${isBlog ? 'blogpost' : 'page'}/${contentId}`;
					const url = getCollabProviderUrl();
					const provider = createSocketIOCollabProvider({
						isPresenceOnly_do_not_use: !isEditPage && !isLivePage,
						documentAri,
						url,
						getUser,
						getAnalyticsWebClient: getAnalyticsWebClient(),
						need404: true,
						productInfo: {
							product: 'confluence',
							subProduct: 'experimentTeammatePresence',
						},
						permissionTokenRefresh,
					});
					// A critical call to establish connection with NCS
					provider.setupPresenceOnly_do_not_use(clientId || `presence-${uuid()}`);
					setCollabEditProvider(provider);
					providerRef.current = provider;
				}
			} catch (error) {
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						source: analyticsSource,
						action: 'errored',
						actionSubject: 'ncsProvider',
						attributes: {
							error: error.message || String(error),
							stack: error.stack,
						},
					},
				}).fire();
				handleError(error);
			} finally {
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						source: analyticsSource,
						action: 'initialized',
						actionSubject: 'ncsProvider',
					},
				}).fire();
				setIsloading(false);
				experienceTracker.succeed({
					name: TEAMMATE_PRESENCE_NCS_PROVIDER,
				});
			}
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[contentId, cloudId, skip],
	);

	useEffect(() => {
		void initializeNCSCollabProvider();
		return () => {
			setIsloading(true);
			if (providerRef.current) {
				providerRef.current.destroy();
			}
		};
	}, [initializeNCSCollabProvider]);

	return {
		provider: skip ? null : collabEditProvider,
		isLoading: skip ? false : isLoading,
		errors: skip ? null : errors,
	};
};
