import React from 'react';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { useNCSProvider } from './useNCSProvider';
import { TeammatePresencePlaceholder } from './TeammatePresencePlaceholder';
import { TeammatePresenceLivePagePlaceholder } from './TeammatePresenceLivePagePlaceholder';

type NCSProviderProps = {
	contentId: string;
	skip?: boolean;
	children?: React.ReactNode;
};

export const NCSProvider = ({ contentId, skip, children }: NCSProviderProps) => {
	const { isLoading } = useNCSProvider(contentId, skip);
	const isEditorActionBarSSREnabled = useBooleanFeatureFlag(
		'confluence.frontend.ssr-editor-actions-bar',
	);
	const isLivePage = useIsLivePage();
	const isEditPage = useIsEditorPage();

	if (isLoading) {
		if (isEditorActionBarSSREnabled) {
			if (isEditPage) {
				return <TeammatePresencePlaceholder />;
			} else if (isLivePage) {
				return <TeammatePresenceLivePagePlaceholder />;
			}
		} else {
			return null;
		}
	}
	return <>{children}</>;
};
