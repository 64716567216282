import React, { useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Flex, Box, xcss } from '@atlaskit/primitives';
import { CustomItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ProfileAvatar } from '@confluence/profile';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { getBorderColorFromSessionId } from './presenceUtils';
import type { PresenceAvatarProps } from './presenceTypes';

const noWrap = xcss({
	whiteSpace: 'nowrap',
});

const nameStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	flexGrow: 1,
	width: '100%',
});

const editModeContainerStyles = xcss({
	height: '52px',
});

const viewModeContainerStyles = xcss({
	height: '44PX',
});

const containerChildStyles = xcss({
	height: '28px',
	lineHeight: '28px',
	flexGrow: 1,
});

const i18n = defineMessages({
	editing: {
		id: 'experiment-teammate-presence.menu.editing',
		defaultMessage: 'Editing',
		description:
			'The text is shown as a title of a section inside of a menu. The people in the section are editing a page',
	},
});

export const TeammatePresenceMenuEditItem = ({
	name,
	activity,
	sessionId,
	userId,
	src,
}: PresenceAvatarProps) => {
	const isEditPage = useIsEditorPage();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shouldFireHoverEvent = useRef<boolean>(true);

	const handleHover = () => {
		if (shouldFireHoverEvent.current) {
			shouldFireHoverEvent.current = false;
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'hovered',
					actionSubject: 'link',
					source: 'presenceMenuEditor',
					actionSubjectId: 'presenceAvatarProfile',
					attributes: {
						isEditPage,
					},
				},
			}).fire();
		}
	};

	return (
		<CustomItem
			component={() => (
				<Box
					paddingInline="space.200"
					paddingBlock={isEditPage ? 'space.150' : 'space.100'}
					xcss={isEditPage ? editModeContainerStyles : viewModeContainerStyles}
				>
					<Flex xcss={containerChildStyles} direction="row" alignItems="stretch">
						<div onMouseEnter={handleHover}>
							<ProfileAvatar
								userId={userId}
								size="small"
								name={name}
								src={src}
								trigger="hover"
								position="left-start"
								canViewProfile
								offset={[0, 24]}
								borderColor={getBorderColorFromSessionId(sessionId)}
							/>
						</div>
						<Box xcss={[nameStyles, noWrap]} paddingInline="space.200">
							{name}
						</Box>
						<Box xcss={noWrap}>{Boolean(activity) && <FormattedMessage {...i18n.editing} />}</Box>
					</Flex>
				</Box>
			)}
		/>
	);
};
