import React, { useState, useCallback, useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Flex, Text, Box, Pressable, xcss } from '@atlaskit/primitives';
import { CustomItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ProfileAvatar } from '@confluence/profile';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useShareToEntities } from '@confluence/share/entry-points/useShareToEntities';
import { PAGE_MODE } from '@confluence/share/entry-points/useCopyContentLink';

import { getBorderColorFromSessionId } from './presenceUtils';
import { useTeammatePresenceState } from './useTeammatePresenceStore';
import type { PresenceAvatarProps } from './presenceTypes';

const pressableStyles = xcss({
	padding: 'space.0',
	cursor: 'default',
});

const underlineStyles = xcss({
	textDecoration: 'underline',
	color: 'color.text.subtle',
	cursor: 'pointer',

	':hover': {
		textDecoration: 'none',
	},
});

const editModeContainerStyles = xcss({
	height: '52px',
});

const viewModeContainerStyles = xcss({
	height: '44PX',
});

const noWrap = xcss({
	whiteSpace: 'nowrap',
});

const nameStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	flexGrow: 1,
	width: '100%',
});

const rowStyles = xcss({
	height: '28px',
	lineHeight: '28px',
});

const nameContainerStyles = xcss({
	height: '38px',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	flexGrow: 1,
	width: '100%',
	overflow: 'hidden',
});

const i18n = defineMessages({
	viewing: {
		id: 'experiment-teammate-presence.menu.viewing',
		defaultMessage: 'Viewing',
		description: 'The text is used to indicate that the current user is `Viewing the page`',
	},
	inviteToEdit: {
		id: 'experiment-teammate-presence.menu.button.invite-to-edit',
		defaultMessage: 'Invite to edit',
		description:
			'This text is shown inside of a button that invites a user to edit the current page',
	},
	invited: {
		id: 'experiment-teammate-presence.menu.button.invited',
		defaultMessage: 'Invited',
		description:
			'This text is shown inside of a button that invites a user to edit the current page',
	},
});

const InviteToEditButton = ({ userId }: Omit<PresenceAvatarProps, 'name' | 'sessionId'>) => {
	const [isInvited, setIsInvited] = useState<boolean>(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { contentId } = useTeammatePresenceState();
	const isEditPage = useIsEditorPage();

	const message = isInvited ? i18n.invited : i18n.inviteToEdit;
	const textColor = isInvited ? 'color.text.disabled' : 'color.text.subtle';

	const { shareToEntities } = useShareToEntities(
		contentId,
		'presenceMenu',
		isEditPage ? PAGE_MODE.EDIT : PAGE_MODE.VIEW,
	);

	const handleClick = useCallback(() => {
		if (isInvited) return;
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'presenceInviteToEdit',
				source: 'presenceMenu',
			},
		}).fire();
		void shareToEntities([{ id: userId, type: 'user' }], isEditPage);
		setIsInvited(true);
	}, [createAnalyticsEvent, setIsInvited, isInvited, isEditPage, shareToEntities, userId]);

	return (
		<Pressable
			xcss={[pressableStyles, !isInvited && underlineStyles]}
			onClick={handleClick}
			backgroundColor="color.background.neutral.subtle"
		>
			<Text size="small" color={textColor}>
				<FormattedMessage {...message} />
			</Text>
		</Pressable>
	);
};

const ViewItemInEditMode = ({ name, userId }: Omit<PresenceAvatarProps, 'sessionId'>) => {
	return (
		<Flex xcss={nameContainerStyles} direction="column" alignItems="start">
			<Box xcss={[nameStyles, noWrap]}>{name}</Box>
			<InviteToEditButton userId={userId} />
		</Flex>
	);
};

const ViewItemInViewMode = ({
	name,
}: Omit<PresenceAvatarProps, 'userId' | 'handleShare' | 'sessionId'>) => {
	return (
		<Box xcss={[nameStyles, noWrap]} paddingInline="space.200">
			{name}
		</Box>
	);
};

export const TeammatePresenceMenuViewItem = ({
	name,
	userId,
	sessionId,
	src,
}: PresenceAvatarProps) => {
	const isEditPage = useIsEditorPage();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shouldFireHoverEvent = useRef<boolean>(true);

	const handleHover = () => {
		if (shouldFireHoverEvent.current) {
			shouldFireHoverEvent.current = false;
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'hovered',
					actionSubject: 'link',
					source: 'presenceMenuViewer',
					actionSubjectId: 'presenceAvatarProfile',
					attributes: {
						isEditPage,
					},
				},
			}).fire();
		}
	};

	return (
		<CustomItem
			component={() => (
				<Box
					paddingInline="space.200"
					paddingBlock="space.100"
					xcss={isEditPage ? editModeContainerStyles : viewModeContainerStyles}
				>
					<Flex
						direction="row"
						alignItems={isEditPage ? 'center' : 'stretch'}
						xcss={!isEditPage && rowStyles}
					>
						<div onMouseEnter={handleHover}>
							<ProfileAvatar
								userId={userId}
								size="small"
								name={name}
								src={src}
								trigger="hover"
								position="left-start"
								canViewProfile
								borderColor={getBorderColorFromSessionId(sessionId)}
								offset={[0, 24]}
							/>
						</div>
						{isEditPage ? ( // Removes the option for the current user inviting themselves
							<ViewItemInEditMode name={name} userId={userId} />
						) : (
							<ViewItemInViewMode name={name} />
						)}
						<Box xcss={noWrap}>
							<FormattedMessage {...i18n.viewing} />
						</Box>
					</Flex>
				</Box>
			)}
		/>
	);
};
